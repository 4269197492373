var WebFont = require('webfontloader');

WebFont.load({
    google: {
        "families": ["Open+Sans:300,400,600,700"]
    },
    custom: {
        "families": ["Flaticon", "Font Awesome 5 Solid", "Font Awesome 5 Regular", "Font Awesome 5 Brands"],
        urls: [BASE + 'assets/css/fonts.css']
    },
    active: function () {
        sessionStorage.fonts = true;
    }
});
